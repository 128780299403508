.font-sm {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
}

.font-md {
  font-size: 1.5rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
}

.font-lg {
  font-size: 2rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
}

.plan-title {
  padding-left: 2rem !important;
}

.checkout-button {
  margin-top: 0rem !important;
  background-color: #1976d2;
  cursor: pointer;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}