@media (max-width:991px) {

  .login-grid {
    height: 100%;
    padding: 0 0 !important;
  }
}

@media (max-width:575px) {

  .login-grid {
    height: 100%;
    padding: 50px 0 !important;
  }
}