.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: var(--main-bg);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.button-group {
  margin-top: 20px;
}

.modal-button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.leave-button {
  background-color: rgb(0, 122, 255);
  color: white;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}