.App-logo {
  width: 100px;
  height: 100px;
  pointer-events: none;
}

.topnav {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px;
  background-color: var(--main-bg);
}

.topnav__search {
  margin-left: 15px;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.topnav__search>input {
  height: 100%;
  width: 100%;
  padding: 10px 60px 10px 43px;
  font-size: 1rem;
  border-radius: 12px;
  color: var(--txt-color);
  background-color: var(--main-bg);
  border: 1px solid rgba(40, 45, 38, 0.15);
}

.topnav__search>i {
  font-size: 1.5rem;
  position: absolute;
  left: 10px;
}

.topnav__search>input:focus {
  outline: none;
  border: 1px solid #ffc400;
}

.topnav__right {
  display: flex;
  align-items: center;
}

.topnav__right-item~.topnav__right-item {
  margin-left: 30px;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 20px;
}

.notification-item:hover {
  background-color: var(--second-bg);
}

.notification-item>i {
  margin-right: 20px;
  font-size: 1.5rem;
}

.topnav__right-user {
  display: flex;
  align-items: center;
}

.topnav__right-user__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.topnav__right-user__image>img {
  width: 100%;
}

.topnav__right-user__name {
  font-size: 1rem;
  font-weight: 600;
}

::-webkit-scrollbar {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.PE_sidebar::-webkit-scrollbar {
  display: none;
  scroll-behavior: smooth;
}

.PE_sidebar .MuiListItemIcon-root {
  min-width: 40px !important;
}

.PE_sidebar .MuiListItem-root {
  padding-top: 4px;
  padding-bottom: 4px;
}

.filter-button {
  display: flex;
  align-items: center;
  border: none;
  background-color: #ffffff;
  padding: 10px 19px;
  border: 1px solid rgba(40, 45, 38, 0.05);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.cancel-filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;

  width: 108px;
  height: 48px;

  border: 1px solid rgba(255, 255, 255, 0.598) !important;

  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 10px;
  color: #d2d2d4 !important;
  cursor: pointer;
}

.order-filter-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  cursor: pointer;

  width: 108px;
  height: 48px;

  background: #fe4646;

  border: 1px solid #fe4646;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  color: white;
}

legend {
  display: block;
  width: auto;
  float: unset;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 7px !important;
}

.MuiPagination-ul {
  justify-content: end;
}

.MuiFormHelperText-root {
  font-size: 1rem !important;
  color: #fc9494 !important;
}

.Mui-error {
  border-color: #fc9494 !important;
}

#short-icon {
  position: relative !important;
  top: 2px !important;
  left: 3px !important;
  cursor: pointer !important;
}


@media (max-width:575px) {

  .plan_text h1 {
    font-size: 18px;
  }

}

.MuiRadio-root {
  padding: 0 !important;
}

.vimeo_video iframe {
  width: 800px !important;
  height: 400px !important;
}

@media (max-width:900px) {
  .vimeo_video iframe {
    width: 526px !important;
    height: 240px !important;
  }
}

@media (max-width:600px) {
  .vimeo_video iframe {
    width: 326px !important;
    height: 190px !important;
  }
}

.background_gradient {
  background: linear-gradient(#0009, #0009);
}

.copy_text_icon {
  font-size: 30px;
}

@media(max-width:900px) {
  .copy_text_icon {
    font-size: 16px;
  }
}