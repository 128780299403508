/* General button styling */
.Button {
  padding: 10px 20px;
  background-color: #cecece;
  width: 100%;
  border-radius: 10px;
  height: fit-content;
  font-weight: 500;
  border: none;
  font-size: 16px;
  border: 2px inset transparent;

}

.Button:hover {
  border: 2px inset rgb(158, 68, 83);
  font-weight: 600;
}

/* User status card grid */
.user-status-card {
  align-content: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* User details card */
.user-details-card {
  padding: 15px;
  /* background-color: #7e80e7; */
  background-color: #2e4877;
  color: white;
  border-radius: 15px;
  width: 350px;
  /* max-width: 350px; */
  box-sizing: border-box;
  min-width: 246px;
}

/* User header */
.user-header {
  display: flex;
  gap: 5px;
  align-items: center;
}

/* User avatar */
.user-avatar {
  /* background-color: black; */
  height: 50px;
  width: 50px;
  border-radius: 5px;
}

/* User info */
.user-info {
  display: flex;
  flex-direction: column;
}

/* User name */
.user-name {
  font-weight: 400;
}

/* User role */
.user-role {
  font-weight: 600;
  font-size: 20px;
}

/* User actions */
.user-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  align-items: center;
}

/* Subscription details */
.subscription-details {
  margin-top: 10px;
}

/* Subscription title */
.subscription-title {
  font-weight: 700;
  font-size: 17px;
  text-align: left;
  text-decoration: underline;
}

/* Subscription info */
.subscription-info {
  margin-top: 15px;
  display: flex;
    flex-direction: column;
    gap: 3px;
}

/* Bold text */
.bold-text {
  font-weight: 600;
}

/* Status item */
.status-item {
  height: fit-content;
}

/* Status item inner */
.status-item-inner {
  background-color: #2e4877;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 350px;
  /* height: fit-content; */
  gap: 10px;
  align-items: center;
}

/* Status icon */
.user-status-icon {
  background-color: black;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Status info */
.status-info {
  display: flex;
  flex-direction: column;
}

/* Status title */
.status-title {
  font-weight: 400;
  color: white;
  display: flex;
  gap: 10px;
}

.user-status-first-card {
  display: flex;
  gap: 20px;
  flex-shrink: 1;
}

/* Status number */
.status-number {
  font-weight: 600;
  color: white;
  font-size: 24px;
}

.status-item {
  border-radius: 8px;
   flex: 1 1 calc(25% - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;
}

.status-item:hover {
  transform: translateY(-5px);
}
/* Responsive design */
@media (max-width: 1600px) {
  .user-status-card {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 25px;
  }

}
@media (max-width: 1164px) {
 .user-status-card {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 25px;
  }
  .user-details-card{
    width: 100%;
  }
 .user-status-first-card {
    flex-direction: column;
  }
}

@media (max-width: 729px) {
 .user-status-card {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 25px;
  }


 .user-status-card {
    flex-direction: column;
  }
 .userdashboard {
    position: relative;
    width: 100%;
  }
}

@media (max-width: 540px) {
 
 .status-item-inner {
    padding: 15px;
  }
 .user-status-icon {
    height: 40px;
    width: 40px;
  }
 .status-number {
    font-size: 18px;
  }
}

@media (max-width: 410px) {
 
 .status-item-inner {
    padding: 10px;
  }
 .user-status-icon {
    height: 30px;
    width: 30px;
  }
 .status-number {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
 .user-details-card {
    max-width: 150px;
  }
.status-item-inner {
    padding: 5px;
  }
.user-status-icon {
    height: 20px;
    width: 20px;
  }
.status-number {
    font-size: 12px;
  }
}
/* .user-status-card 
.status-item .status-info .status-title  */

.user-status-infoicon{
  position: relative;
}