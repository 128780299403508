.table-container {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  overflow-x: auto;
}

.newtable {
  width: 100%;
  border-collapse: collapse; 
  min-width: 600px;
display: table;
}

.newtable th, .newtable td {
  border: 2px inset #202c42; 
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.newtable .tableheader {
  /* background-color: var(--main-color-blue); */
  background-color: #65a6fa;
  color: #1a2029;
  font-weight: 700;
}

.newtable .tablebody {
  font-weight: 600;
}

.newtable tbody tr:hover {
  cursor: pointer;
  background-color: #7b7ee3;
}

.newtable tbody .selected {
  background-color: #7b7ee3;
}

.newtable tbody .subdata:hover {
  cursor: pointer;
  color: #7b7ee3;
  border: 3px transparent #7b7ee3; 
}

.subdata {
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

.subdata .tablebody {
  font-weight: 400;
}

.chapter-details {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .newtable th, .newtable td {
      font-size: 12px;
      padding: 6px;
  }

  .table-container {
      padding: 20px;
  }

  .chapter-details {
      font-size: 14px;
  }
}

@media (max-width: 480px) {
  .newtable th, .newtable td {
      font-size: 10px;
      padding: 4px;
  }

  .chapter-details {
      font-size: 12px;
  }
}
