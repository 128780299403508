/* .sidebar-elements .false {
  display: none;
}
.sidebar {
  width: 60px;
  transition: width 0.3s ease;
}

.sidebar.expanded {
  width: fit-content;
}
@media (max-width: 991px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .avatar {
    margin-left: 5rem;
  }

  .navContainer {
    gap: 10px;
    justify-content: unset;
  }

  .sidebar-elements {
    position: absolute;
    left: 0;
    background-color: #000000;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 9999;
  }

  .sidebar-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .nav-elements ul li:not(:last-child) {
    margin-right: unset;
  }
} */

/* src/Sidebar.css */
.sidebar {
  position: relative;
  top: 0;
  left: 0;
  width: 220px;
  height: 100%;
  background: #2e4877;
  color: #ecf0f1;
  transition: width 0.3s;
  overflow: scroll;
  white-space: nowrap;
  overflow-y: scroll;
}

.sidebar.open {
  width: 220px;
}

.sidebar .logo {
  padding: 10px;
  text-align: center;
  width: 100px;
}

.sidebar .menu {
  padding: 0;
  list-style: none;
}

.sidebar .menu li {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: inherit;
}

.sidebar .menu li span {
  margin-left: 10px;
  display: inline-block;
  transition: opacity 0.3s;
  display: none;
}

.sidebar.open .menu li span {
  display: block;
}

/* .sidebar .menu li:hover {
  background: #63a2f5;

} */

.sidebar .menu li a {
  color: #ecf0f1;
  text-decoration: none;
  display: block;
  width: 100%;
}

/* .sidebar .menu li a:hover {
  background: #63a2f5;
} */

.sidebar_icon {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

@media (max-width: 1100px) {
  .sidebar_menus {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
}

.sidebar_menus {
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar_menus.active {
  background-color: #63a2f5;
  width: 100%;
}

.menu-button {
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
}

.menu-button img {
  width: 30px;
  height: 30px;
}

@media (max-width: 1135px) {
  .menu-button {
    display: flex;
    position: relative;
  }

  .sidebar-elements {
    width: 0 !important;
  }

  .sidebar {
    width: 0;
  }

  .menu-button {
    top: 0px;
    left: 30px;
  }

  .sidebar.open {
    width: 200px;
    z-index: 1000;
  }
}
