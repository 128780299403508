.graph-container {
  height: 100%;
}

.chart-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.apexcharts-zoomin-icon svg,
.apexcharts-zoomout-icon svg,
.apexcharts-reset-icon,
.apexcharts-reset-icon svg,
.apexcharts-menu-icon svg,
.apexcharts-selected svg {
  fill: #e3e3e3 !important;
}
.apexcharts-legend-text {
  color: #ffffff !important;
}


@media (max-width: 780px) {
  .test-chart{
    display: flex;
    flex-direction: column;

  }
}
@media (max-width: 480px) {
  .test-chart{
    display: flex;
    flex-direction: columns;
  }

}
.dashboard-status-card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  height: 100%;
  margin-bottom: 20px;
}
@media (max-width: 1552px) {
  .dashboard-status-card {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1360px) {
  .dashboard-status-card {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1135px) {
  .dashboard-status-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .dashboard-status-card {
    grid-template-columns: repeat(1, 1fr);
  }
}
.dashboard-status-title {
  font-weight: 400;
  color: white;
  display: flex;
  gap: 10px;
  font-size: 13px;
}
.dashboard-status-number {
  font-weight: 600;
  color: white;
  font-size: 26px;
  width: fit-content;
}
.dashboard-status-card {

  gap: 16px;
}

.dashboard-status-item {
  border-radius: 8px;
   flex: 1 1 calc(25% - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;
}

.dashboard-status-item:hover {
  transform: translateY(-5px);
}

.dashboard-status-item-inner {
  background-color: #2e4877;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}

/* Status icon */
.status-icon {
  background-color: black;
  height: 60px;
  width: 60px;
  border-radius: 10px;
  object-fit: contain;
}


@media (max-width: 768px) {
  .dashboard-status-item {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .dashboard-status-item {
    flex: 1 1 100%;
  }
}
.toggle-container {
  display: flex;
  border: 2px solid #fff;
  border-radius: 25px;
  overflow: hidden;
  width: fit-content;
  background-color: #2e2e2e;
}

.toggle-button {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #2e2e2e;
  transition: background-color 0.3s, color 0.3s;
  width: 130px;
  height: 50px;
  text-align: center;
}

.toggle-button.active {
  background-color: #3a6ea5;
  color: #fff;
}

@media (max-width: 768px) {
  .toggle-button{
    width: 120px;
  }
}

@media (max-width: 480px) {
  .toggle-button{
    width: 80px;
  }
}
