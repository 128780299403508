.status-card {
  padding: 26px 20px;
  display: flex;
  align-items: center;
  background-color: #2e4777;
  box-shadow: hsla(210, 8%, 62%, 0.2) 0px 8px 24px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 0.5s ease 0s;
  margin-bottom: 30px;
  height: 7rem;
}

.status-card__icon {
  width: 48px;
  height: 48px;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background: #000000;
  color: #ffffff;
  border-radius: 10px;
}

.status-card__info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  margin-left: 15px;
  z-index: 1;
  text-transform: capitalize;
  color: #f2f2f2;
}

.status-card__info p {
  margin: 3px 0px;
}


.status-card:hover::before {
  transform: scale(3);
}

.status-card:hover {
  color: #fff;
}

.status-card__info_count {
  font-size: 20px;
  font-weight: 600;
}
