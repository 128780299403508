.score-bar {
    position: relative;
    width: 100%;
    height: 35px;
    border-radius: 17px;
    background-color: #f4f5f7;
    overflow: hidden;
}

.answerCount {
    border: 1px solid #dbd0d0;
    width: fit-content;
    min-width: 25px;
    height: 25px;
    border-radius: 45%;
    text-align: center;
    font-size: 12px;
    background-color: #544c4c82;
    padding: 4px;
}

.fontLights {
    color: #dbd0d0;
    font-size: 13px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.fontLightsForResult {
    color: #dbd0d0;
    font-size: 13px;
    margin-bottom: auto;
    margin-top: auto;
}

.resultHeader {
    color: #efefef;
    font-weight: bold !important;
}

.divider {
    background-color: #909090;
}

.mainGrind {
    margin-top: 30px;
}

.analysisHeadding {
    margin-top: auto;
    margin-bottom: auto;
}