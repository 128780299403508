.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.description-item {
  display: flex;
  column-gap: 5px;
}
.description-box {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid rgb(255, 255, 255);
  width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;
  border-radius: 8px;
  justify-content: space-between;
}

.planExpirationDuration {
  margin-top: 0px !important;
}
