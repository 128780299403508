.timePerQuestion {
    margin-top: 20px !important;
}

.updateButton {
    margin-bottom: 20px !important;
    height: 40px;
}

.timePerQuestions {
    margin-bottom: 13px !important;
}