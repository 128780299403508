.unit-table-container {
  width: 100%;
  margin: 0 auto;
  overflow-x: auto; /* Horizontal scrolling */

}

.unit-newtable {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.unit-newtable .unit-tableheader,
.unit-newtable .unit-tablebody {
  border: 1px inset #202c42;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.unit-newtable .unit-tableheader {
  padding-top: 15px;
  padding-bottom: 15px;
  /* background-color: var(--main-color-blue); */
  background-color: #65a6fa;

  color: #1a2029;
  font-weight: 700;
  border-color: #1a2029;
  border-width: 2px;
}

.unit-newtable tbody tr:hover {
  border: 1px solid #7b7ee3;
  cursor: pointer;
}

.chapter-details {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .unit-newtable .unit-tableheader,
  .unit-newtable .unit-tablebody {
    font-size: 12px;
    padding: 6px;
  }

  .unit-table-container {
    padding: 10px;
  }

  .chapter-details {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .unit-newtable .unit-tableheader,
  .unit-newtable .unit-tablebody {
    font-size: 10px;
    padding: 4px;
  }

  .unit-table-container {
    padding: 5px;
  }

  .chapter-details {
    font-size: 12px;
  }
}
