* {
  font-family: "Montserrat", sans-serif !important;
}

code {
  font-family: "Inter", sans-serif;
}

body {
  height: 100% !important;
  margin: 0 !important;
  background-color: #282d26;
}

:root {
  --main-bg: #2e4777;
  --second-bg: #f8f8f8;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --main-table-color: #041d496b;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #f8f8f8;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: #62b4ff;

  --main-color-red: #fb0b12;
  --second-color-red: #ff4a6b;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: #2ae9e6;

  --main-color-green: #019707;
  --second-color-green: #4caf50;

  --main-color-orange: #d68102;
  --second-color-orange: #fca11a;

  --sidebar-width: 127px;
  --border-radius: 15px;
  --topnav-height: 90px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.apexcharts-text {
  color: white !important;
}

body {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
}

a {
  text-decoration: none;
  color: unset;
}

.title {
  margin-bottom: 2rem;
  color: #efefef;
}

/* a:hover {
  color: var(--main-color);
} */

.page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
  font-weight: 600 !important;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.ant-table-thead > tr > th {
  background: #ffffff;
  border: none;
}

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid rgba(40, 45, 38, 0.15);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 8px 15px;
}

.status-pending {
  background: rgba(255, 196, 0, 0.05);
  color: #ffc400;
}

.status-shipped {
  background: rgba(18, 183, 106, 0.05);
  color: #12b76a;
}

.status-deliver {
  background: rgba(18, 183, 106, 0.05);
  color: #12b76a;
}

.fontLight {
  color: #efefef;
}

.cancel-btn {
  margin-right: 10px !important;
}

.status-cancel {
  background: rgba(247, 144, 9, 0.05);
  color: #f04438;
}

.tableCell {
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #efefef !important;
}

.input {
  height: 50px;
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(40, 45, 38, 0.15);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0px 10px;
}

.input:focus {
  outline: none;
  border: 1px solid #ffc400;
}

.input-label {
  font-weight: 500;
  font-size: 14px;
  color: #282d26;
  margin-bottom: 5px;
}

.loader-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-bottom-drawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-bottom-drawer select {
  border: 1px solid rgba(40, 45, 38, 0.05);
  border-radius: 5px;
  padding: 5px 3px;
}

.table-bottom-drawer select:focus {
  outline: none;
  border: 1px solid #ffc400;
}

.table-bottom-drawer > div {
  display: flex;
  align-items: center;
}

.page-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: rgba(40, 45, 38, 0.25);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 5px;
  width: 92px;
  height: 40px;
}

.page-buttons:hover {
  border: none;
  background: #ffc400;
  color: #ffffff;
}

.page-number {
  width: 35px;
  height: 40px;
  border: none;
  background: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 5px;
}

.page-number:hover {
  border: 1px solid #ffc400;
  color: #ffc400;
}

.close-button {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
}

.form-action-button {
  display: flex;
  align-items: center;
  border: none;
  background-color: #ffffff;
  border: 1px solid rgba(40, 45, 38, 0.05);
  padding: 10px 19px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.form-action-button-submit {
  display: flex;
  align-items: center;
  border: none;
  background-color: #ffc400;
  padding: 10px 19px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}

.input-view {
  height: 50px;
  width: 100%;
  background: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(40, 45, 38, 0.15);
  box-shadow: none;
  margin-bottom: 10px;
  padding-left: 10px;
}

.page-path {
  position: relative;
  top: -28px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #282d26;
}

.sidebar:hover + .layout__content {
  padding-left: 260px;
}
