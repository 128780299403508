.login-img {
  padding: 20px;
}

.login-grid {
    max-width:1150px;
    position: absolute;
    width: 100%;
    height: 520px;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    margin:20px 0;
    overflow: scroll;
}

img.login-img {
  width: 100%;
  max-width: 440px;
}



@media (max-width:575px) {
  img  {
    width: 100%;
    max-width: 100%;
  }

  
.login-grid {
  height: 100%;
  padding: 60px 0;
}
}