.login-grid {
    max-width:1150px;
    position: absolute;
    width: 100%;
    height: 520px;
    left: 50%;
    top: 52%;
    transform: translate(-50%, -50%);
    overflow: scroll;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .login-grid {
    height: 685px!important;
    padding: 0 0!important;
}
}


@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation: landscape) {
  .login-grid {
    height: 685px!important;
    padding: 50px 0!important;
  }
}


@media (max-width:991px) {
  .login-grid {
    height: 685px!important;
    
  }
  
  }

  @media (max-width:575px) {
  
    .login-grid {
      height: 100%;
      margin: 20px 0!important;
      
    }

    .css-1oqqzyl-MuiContainer-root {
      padding-left: 0!important; 
      padding-right: 0!important;
  }


    }