.userTable {
  width: 100%;
  margin-bottom: 20px;
  border: 1px;
}
.user-table-container {
  width: 100%;
    margin: 0 auto;
  overflow-x: auto; /* Add this line for horizontal scrolling */
}
.userheader,
.userbody {
  border: 1px solid #202c42;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  padding-top: 15px;
  cursor: pointer;

  padding-bottom: 15px;
}

.userheader {
  padding-top: 15px;
  padding-bottom: 15px;
  /* background-color: var(--main-color-blue); */
  background-color: #65a6fa;
  color: #1a2029;
  font-weight: 700;
  border-color: #1a2029;
  border-width: 1px;
  text-align: center;
  cursor: pointer;
}

.see-all-users {
  padding: 8px 18px;
  background-color: #fff;
  border: none;
  color: black;
  cursor: pointer;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
}

.userTable tbody tr td:hover {
  /* border: 1px inset #7b7ee3; */
  border: 2px inset #7b7ee3;
  cursor: pointer;
  color: #7b7ee3;
}

@media (max-width: 768px) {
  .table-header {
    font-size: 18px;
    flex-direction: column;
    align-items: flex-start;
  }

  .userTable .userheader,
  .userTable .userbody {
    font-size: 12px;
    padding: 6px;
  }

  .see-all-users {
    font-size: 12px;
    padding: 6px 12px;
  }
}

@media (max-width: 480px) {
  .table-header {
    font-size: 16px;
  }

  .userTable .userheader,
  .userTable .userbody {
    font-size: 10px;
    padding: 4px;
  }

  .see-all-users {
    font-size: 10px;
    padding: 4px 8px;
  }
}
.userTable .userheader .iconstyle{
  font-size: 1rem;
  position: relative;
  top: 2px;
  left: 2px;
  cursor: pointer;
}