.ranges{
    background-color: #2e4777;
    color: white;
}
.table-condensed {
    background-color: #2e4777;
    color: white;
}
.daterangepicker td.off{
    background-color: #2e4777;
}
.drp-buttons{
    background-color: #2e4777;
    color: white;
}
.daterangepicker{
    background-color: #2e4777;
}
.daterangepicker .ranges li:hover{
    /* background-color: transparent ; */
    color: black;
}
.daterangepicker td.available:hover{
    background-color: cornflowerblue;
    border-color: transparent;
    color: inherit;
}