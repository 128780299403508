.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  padding-bottom: 20px;
}

.chart {
  width: 100%;
  height: 100%;
}
