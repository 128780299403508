.table-container {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  overflow-x: auto; /* Add this line for horizontal scrolling */
}

.newtable {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Add this line to ensure table doesn't shrink too much */
}
/* .table-heading{
  display: flex;
  align-items: center;
  width: 100%;
} */
.newtable .tableheader {
  border: 1px inset #202c42;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.newtable .tablebody {
  border: 1px inset #202c42;
  padding: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
}

.newtable .tableheader {
  /* background-color: var(--main-color-blue); */
  background-color: #65a6fa;
  align-items: center;
  color: #1a2029;
  font-weight: 700;
  border-color: #1a2029;
  text-align: center;
}

.newtable tbody tr:hover {
  cursor: pointer;
  background-color: #7b7ee3;
border:1px solid #7b7ee3
}

.newtable tbody .selected {
  background-color: #7b7ee3;
}

.newtable tbody .subdata:hover {
  cursor: pointer;
}

.chapter-details {
  margin-top: 20px;
}

.subdata {
  border-collapse: collapse;
  border: 1px inset #202c42;
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

.subdata .tablebody {
  font-weight: 400;
}

@media (max-width: 768px) {
  .newtable .tableheader,
  .newtable .tablebody {
    font-size: 12px;
    padding: 6px;
  }

  .table-container {
    padding: 20px;
  }

  .chapter-details {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .newtable .tableheader,
  .newtable .tablebody {
    font-size: 10px;
    padding: 4px;
  }

  .chapter-details {
    font-size: 12px;
  }
}
.newtable .tableheader .iconstyle{
  font-size: 1rem;
  position: relative;
  top: 2px;
  left: 3px;
  cursor: pointer;
}