.font-sm {
  font-size: 1rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
}
.font-md {
  font-size: 1.5rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
}
.font-lg {
  font-size: 2rem;
  font-weight: 400;
  padding-top: 5px;
  padding-bottom: 5px;
}
.plan-title {
  padding-left: 2rem !important;
}
.cart-button {
  margin-top: 1rem !important;
  background-color: #1976d2;
  cursor: pointer;
}


@media (max-width:767px) {
  .plan-title {
    padding-left: 0rem !important;
  }
}

