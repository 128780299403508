.main-question {
  padding: 2rem;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.preview {
  padding: 1rem;
  margin-top: 1rem;
}

.description-boxs {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0px;
  /* border: 1px solid rgb(25, 118, 210); */
  min-width: 100%;
  margin-top: 10px;
  /* flex-wrap: wrap; */
  border-radius: 8px;
  justify-content: space-between;
}

.image-container {
  margin-top: 18px;
  display: flex;
  flex-flow: row wrap;
}

.image-wrapper {
  position: relative;
  margin: 25px 5px 5px;
  width: 147px;
  height: 140px;
  background-repeat: no-repeat;
  background-size: cover;
}

.abc .MuiOutlinedInput-notchedOutline {
  border-color: aliceblue !important;
}

.answer-radio {
  width: 88%;
}