.box-plan {
  border: 1px solid;
  border-color: #3949ab;
}
.box-plan:hover {
  border-color: #989aaa;
}
li.check {
  font-size: 16px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.plan-price {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700 !important;
  margin-top: 1rem;
}
.plan-name {
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.plan-duration {
  margin-top: 0px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
.plan-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
}





@media (min-width:1024px) {
.price_grid {
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  width: 100%;
  margin: 50px auto;
}
}

@media (max-width:1023px) {
  .price_grid {
    margin: 50px auto;
  }
  }