.navContainer {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar {
  height: 70px;
  background-color: #000000;
  position: relative;
}

.logo-mbl {
  display: none;
}

.trailbtn {
  color: #115fd2;
  border-radius: 0.5rem;
  border: 1px solid #115fd2;
  background-color: transparent;
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.trailbtn:hover {
  color: #fff;
  background-color: #115fd2;
}

.clear-btn {
  display: none !important;
}

.logobar {
  display: flex;
  align-items: center;
  gap: 1px;
}

.logobar:hover {
  color: white;
}

.logobar h2 {
  font-size: 1.5rem;
  font-weight: 400;
}

.logo {
  width: auto;
  height: 2.8rem;
}

.menu-item {
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
}

.nav-elements {}

.animation-hover {
  padding-bottom: 5px;
  cursor: pointer;
}

.animation-hover:hover {
  color: #136cf0;

  border-bottom: 2.5px solid #136cf0;
}

/* .animation-hover a:hover {
  color: var(--main-color);
} */
/* .animation-hover:after {
  background: none repeat scroll 0 0 transparent;
  content: "";
  height: 2px;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  position: absolute;
  left: 50%;
  bottom: 0px;
  display: block;
  width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(19 108 240 / var(--tw-bg-opacity));
}

.animation-hover:hover:after {
  left: 10%;
  width: 1%;
} */
.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  align-items: center;
}

.nav-elements ul li {
  margin-right: 14px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-elements ul a {
  font-size: 14px;
  font-weight: 500;
  color: #efefef;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

.cart {
  margin-right: 1rem;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {}

@media (max-width: 991px) {
  .nav-elements ul li {
    margin-right: 8px;
  }

  .navContainer {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .nav-elements ul a {
    font-size: 14px;
    font-weight: 500;
  }

  .logo {
    width: auto;
    height: 2.5rem;
  }

  .trailbtn {
    font-size: 14px;
    font-weight: 500;

    color: #136cf0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .menu-icon {
    display: flex;
    cursor: pointer;
  }

  .menu-item {
    display: contents;
  }

  .nav-elements {
    position: absolute;
    left: 0;
    top: 5px;
    background-color: #000000;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
  }

  .nav-elements ul li:not(:last-child) {
    margin-right: unset;
  }

  .nav-elements ul a {
    font-size: 16px;
    font-weight: 500;
  }

  .logo {
    width: auto;
    height: 2.5rem;
  }

  .clear-btn {
    display: block !important;
  }

  .logo-side {
    width: auto;
    height: 3rem;
  }

  .logo-mbl {
    display: block;
  }

  .avatar {
    margin-left: 5rem;
  }

  .navContainer {
    gap: 10px;
    justify-content: space-between;
    padding: 0;
  }

  .trailbtn {
    font-size: 16px;
    font-weight: 500;

    color: #136cf0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }



  .navbar {
    padding: 0 20px;
    position: relative;
  }
}

@media (max-width: 767px) {}