.videoThumb {
  width: 330px;
  height: 200px;
  border-radius: 15px;
}

.disable {
  mask-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 0%);
}

.videoPlayIcon {
  display: inline-block;
  position: relative;
}

.videoNotPlayIcon {
  display: inline-block;
  position: relative;
}

.videoPlayIcon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-image: url("../../assets/images/play-btn.png");
  cursor: pointer;
  background-size: cover;
  content: "";
}

.videoNotPlayIcon::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background-image: url("../../assets/images/cancel.png");
  cursor: pointer;
  background-size: cover;
  content: "";
}

@media only screen and (max-width: 600px) {
  .videoThumb {
    width: 24rem;
    height: 15rem;
  }
}

@media only screen and (min-width: 600px) {
  .videoThumb {
    width: 170px;
    height: 120px;

  }
}

@media only screen and (min-width: 992px) {
  .videoThumb {
    width: 270px;
    height: 170px;
    border-radius: 15px;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 768px) {
  .videoThumb {
    width: 170px;
    height: 115px;
    border-radius: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .videoThumb {
    width: 250px;
    height: 150px;
    border-radius: 15px;
  }
}

@media screen and (min-width: 1324px) {
  .videoThumb {
    width: 330px;
    height: 200px;
    border-radius: 15px;
  }
}