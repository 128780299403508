.fade {
    cursor: pointer;
    padding: 5px;
    text-align: center;
    width: 50%;
    color: #bfbcbc;
}

.active-tab {
    cursor: pointer;
    border: 1px solid rgb(255, 255, 255);
    text-align: center;
    padding: 5px;
    width: 50%;
    color: #ffffff;
}

.MuiCheckbox-colorPrimary.Mui-checked {
    color: #ffffff !important;
}

.videoTic {
    width: 44px;
    height: 43px;
}

.show {
    display: block;
}

.hide {
    display: none;
}

@media (max-width:575px) {
    .fade {

        width: 100%;
    }

    .active-tab {
        width: 100%;
    }

    .videouse {
        width: 20%;
    }

    .videoTic {
        width: 48px;
        height: 44px;
    }
}

@media (max-width:991px) {

    .videouse {
        width: 60%;
    }
}

@media (max-width:1200px) {

    .videouse {
        width: 80%;
    }
}

@media (max-width:1800px) {

    .videouse {
        width: 100%;
    }
}

.mb_2 {
    margin-bottom: 5px !important;
}

.vp-center {
    justify-content: flex-end !important;
}

.videouse iframe {
    width: 430px !important;
}

@media (max-width:500px) {
    .videouse iframe {
        width: 300px !important;
        height: 170px !important;
    }
}