.questionImage {
  display: flex;
  margin: 3vh auto auto;
  margin-left: 30px;
}

.questionImage img {
  width: 100%;
  height: 100%;
}

.question {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 4vh;
  margin-left: 25px;
}

.questionAnswer {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 4vh;
  margin-left: 35px;
}

.submitBtn {
  width: fit-content;
  height: fit-content;
  display: flex;
  margin-top: 4vh;
  margin-left: 35px;
  margin-bottom: 8vh;
}
.testSection {
  padding-bottom: 4rem;
}
.row {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: auto;
  margin-right: 15px;
  margin-bottom: auto;
  justify-content: flex-end;
  color: white;
}

.row1 {
  width: 6%;
  height: fit-content;
  display: flex;
  margin-top: auto;
  margin-right: 15px;
  margin-bottom: auto;
  color: white;
}

.subbtn {
  width: 100%;
  margin-top: 3vh;
  margin-left: 40px;
  margin-bottom: 2rem;
}

.hr {
  width: 1px;
  height: 100%;
  background-color: black;
  margin-top: 3vh;
}

.questionExplaination {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 3vh;
  margin-left: 35px;
}

.questionExplainationImage {
  display: flex;
  border-radius: 50%;
  margin: 3vh auto auto;
}

.questionExplainationImage img {
  width: 100%;
  height: 100%;
}

.explainationContent {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 4vh;
  margin-left: 15px;
}

.icon {
  margin-top: auto;
  margin-bottom: auto;
}

.margin {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.video-sm {
  display: none;
}
.video-lg {
  display: block;
}
@media (max-width: 600px) {
  .testQue {
    text-align: center;
  }
  .testTimer {
    justify-content: center;
  }
  .testSection {
    padding-left: 10px;
    padding-right: 10px;
  }
  .questionImage {
    display: flex;
    margin: 3vh auto auto;
    margin-left: 0px;
  }
  .question {
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: 4vh;
    margin-left: 0px;
  }
  .questionAnswer {
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: 4vh;
    margin-left: 0px;
  }
  .submitBtn {
    width: fit-content;
    height: fit-content;
    display: flex;
    margin-top: 4vh;
    margin-left: 0px;
    margin-bottom: 8vh;
  }
  .subbtn {
    width: 100%;
    margin-top: 3vh;
    margin-left: 0px;
    margin-bottom: 2rem;
  }
  .questionExplaination {
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: 3vh;
    margin-left: 0px;
  }
  .explainationContent {
    width: 100%;
    height: fit-content;
    display: flex;
    margin-top: 4vh;
    margin-left: 0px;
  }
  .video-sm {
    display: block;
  }
  .video-lg {
    display: none;
  }
}

@media (max-width: 900px) {
  .video-sm {
    display: block;
  }
  .video-lg {
    display: none;
  }
}
