.title {
  margin-bottom: 2rem;
  color: #efefef;
}

.text-end {
  text-align: end !important;
}

.searchBar {
  display: flex;
  justify-content: space-between;
  width: 25%;
  margin-left: 10px;
  margin-bottom: 10px;
}

.borderLight {
  border-color: #f00000 !important;
  color: aliceblue !important;
}

.noDataFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}


.filter-button {
  background-color: #1976D2 !important;
  color: white !important;
  border-color: none !important;
}